/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    supportedLngs: ['es', 'en', 'fr'],
    fallbackLng: 'es',
    lng: localStorage.getItem('i18nextLng'),
    resources: {
      es: {
        translation: require('./locales/es/translations.json'),
      },
      en: {
        translation: require('./locales/en/translations.json'),
      },
      fr: {
        translation: require('./locales/fr/translations.json'),
      },
    },
  });

i18n.languages = ['es', 'en', 'fr'];

export default i18n;
