/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';

import diagramTypes from 'constants/diagramTypes';

import CompanyLogo from 'assets/images/logo-company.png';

import formatData from './utils/formatData';
import getChildren from './utils/getChildren';
import addAbsolutePercents from './utils/addAbsolutePercents';

import ChartItem from './components/ChartItem';

import './OrganizationChart.scss';

const OrganizationChart = ({
  actualSociety,
  societies,
  diagramType = diagramTypes.HOLDING,
}) => {
  const [chartData, setChartData] = useState({});

  function transformData(input) {
    const root = formatData(input[0][0], diagramType);
    root.children = getChildren(input[0][0], 1, input, diagramType);

    return root;
  }

  useEffect(() => {
    const mainSociety = {
      holdingId: actualSociety._id,
      holdingName: actualSociety.name,
      holdingLogo: actualSociety?.additional?.logo || CompanyLogo,
      holdingCIF: actualSociety?.cif,
      holdingShares: null,
      holdingPercentND: null,
      holdingPercentFD: null,
      holdingUserType: actualSociety?.userType,
      holdingEmail: actualSociety?.email,
    };
    const allSocieties = [[mainSociety], ...societies];

    const chart = transformData(allSocieties);

    const chartWithAbsolutePercents = addAbsolutePercents(chart);

    setChartData({
      ...chartWithAbsolutePercents,
    });
  }, [societies]); // eslint-disable-line

  useEffect(() => {
    const container = document.querySelector('.reactOrgChart');
    const content = document.querySelector('.orgNodeChildGroup');

    if (!container || !content) return;

    container.scrollLeft = (content.clientWidth - container.clientWidth) / 2;
  }, [chartData]);

  return (
    <div className="nk-block chart-container chart-container__bordered">
      <OrgChart tree={chartData} NodeComponent={ChartItem} />

      <div className="fading-effect" />
    </div>
  );
};

export default OrganizationChart;
