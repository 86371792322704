/* eslint-disable arrow-body-style */
import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import i18n from 'i18n/config';
import {
  getFullAddress,
  getPartnerNDPercent,
  getPartnerPremium,
} from 'utils/filters';
import { formatNumber } from 'utils/formats';

function oldTags({ plan, society, partner, beneficiary }) {
  const societyTags = society && {
    title: 'Sociedad',
    url: '{{Sociedad}}',
    menu: [
      {
        value: 'SOCIEDAD_NOMBRE',
        title: 'SOCIEDAD_NOMBRE',
        url: '{{SOCIEDAD_NOMBRE}}',
      },
      {
        value: 'SOCIEDAD_CIF',
        title: 'SOCIEDAD_CIF',
        url: '{{SOCIEDAD_CIF}}',
      },
      {
        value: 'SOCIEDAD_DIRECCION',
        title: 'SOCIEDAD_DIRECCION',
        url: '{{SOCIEDAD_DIRECCION}}',
      },
      {
        value: 'SOCIEDAD_CODIGO_POSTAL',
        title: 'SOCIEDAD_CODIGO_POSTAL',
        url: '{{SOCIEDAD_CODIGO_POSTAL}}',
      },
      {
        value: 'SOCIEDAD_CIUDAD',
        title: 'SOCIEDAD_CIUDAD',
        url: '{{SOCIEDAD_CIUDAD}}',
      },
      {
        value: 'SOCIEDAD_PAIS',
        title: 'SOCIEDAD_PAIS',
        url: '{{SOCIEDAD_PAIS}}',
      },
      {
        value: 'SOCIEDAD_LOGO',
        title: 'SOCIEDAD_LOGO',
        url: '{{SOCIEDAD_LOGO}}',
      },
      {
        value: 'SOCIEDAD_ADMINISTRADOR',
        title: 'SOCIEDAD_ADMINISTRADOR',
        url: '{{SOCIEDAD_ADMINISTRADOR}}',
      },
      {
        value: 'FIRMA_SOCIEDAD',
        title: 'FIRMA_SOCIEDAD',
        url: '{{FIRMA_SOCIEDAD}}',
      },
    ],
  };

  const planTags = plan && {
    title: 'Plan',
    url: '{{Plan}}',
    menu: [
      {
        title: 'PLAN_TIPO',
        url: '{{PLAN_TIPO}}',
        value: 'PLAN_TIPO',
      },
      {
        title: 'PLAN_NOMBRE',
        url: '{{PLAN_NOMBRE}}',
        value: 'PLAN_NOMBRE',
      },
      {
        title: 'PLAN_FECHA_INICIO',
        url: '{{PLAN_FECHA_INICIO}}',
        value: 'PLAN_FECHA_INICIO',
      },
      {
        title: 'PLAN_PRECIO_ACCION',
        url: '{{PLAN_PRECIO_ACCION}}',
        value: 'PLAN_PRECIO_ACCION',
      },
      {
        title: 'PLAN_PRECIO_ACCION_FIJO',
        url: '{{PLAN_PRECIO_ACCION_FIJO}}',
        value: 'PLAN_PRECIO_ACCION_FIJO',
      },
      {
        title: 'PLAN_CLIFF',
        url: '{{PLAN_CLIFF}}',
        value: 'PLAN_CLIFF',
      },
      {
        title: 'PLAN_VESTING',
        url: '{{PLAN_VESTING}}',
        value: 'PLAN_VESTING',
      },
      {
        title: 'PLAN_CONSOLIDACION',
        url: '{{PLAN_CONSOLIDACION}}',
        value: 'PLAN_CONSOLIDACION',
      },
      {
        title: 'PLAN_CONSOLIDACION_PERSONALIZADA',
        url: '{{PLAN_CONSOLIDACION_PERSONALIZADA}}',
        value: 'PLAN_CONSOLIDACION_PERSONALIZADA',
      },
    ],
  };

  const partnerTags = partner && {
    title: 'Socio',
    url: '{{Socio}}',
    menu: [
      {
        title: 'SOCIO_NOMBRE_Y_APELLIDOS',
        url: '{{SOCIO_NOMBRE_Y_APELLIDOS}}',
        value: 'SOCIO_NOMBRE_Y_APELLIDOS',
      },
      {
        title: 'SOCIO_TAX_ID',
        url: '{{SOCIO_TAX_ID}}',
        value: 'SOCIO_TAX_ID',
      },
      {
        title: 'SOCIO_DIRECCION',
        url: '{{SOCIO_DIRECCION}}',
        value: 'SOCIO_DIRECCION',
      },
      {
        title: 'SOCIO_EMAIL',
        url: '{{SOCIO_EMAIL}}',
        value: 'SOCIO_EMAIL',
      },
      {
        title: 'SOCIO_No_PARTICIPACIONES',
        url: '{{SOCIO_No_PARTICIPACIONES}}',
        value: 'SOCIO_No_PARTICIPACIONES',
      },
      {
        title: 'SOCIO_PORCENTAJE_ND',
        url: '{{SOCIO_PORCENTAJE_ND}}',
        value: 'SOCIO_PORCENTAJE_ND',
      },
      {
        title: 'SOCIO_VALOR_PARTICIPACION',
        url: '{{SOCIO_VALOR_PARTICIPACION}}',
        value: 'SOCIO_VALOR_PARTICIPACION',
      },
      {
        title: 'SOCIO_NACIONALIDAD',
        url: '{{SOCIO_NACIONALIDAD}}',
        value: 'SOCIO_NACIONALIDAD',
      },
    ],
  };

  const beneficiaryTags = beneficiary && {
    title: 'Beneficiario',
    url: '{{Beneficiario}}',
    menu: [
      {
        title: 'BENEFICIARIO_PARTICIPACIONES',
        url: '{{BENEFICIARIO_PARTICIPACIONES}}',
        value: 'BENEFICIARIO_PARTICIPACIONES',
      },
      {
        title: 'BENEFICIARIO_CATEGORIA_LABORAL',
        url: '{{BENEFICIARIO_CATEGORIA_LABORAL}}',
        value: 'BENEFICIARIO_CATEGORIA_LABORAL',
      },
      {
        title: 'BENEFICIARIO_CIF',
        url: '{{BENEFICIARIO_CIF}}',
        value: 'BENEFICIARIO_CIF',
      },
      {
        title: 'BENEFICIARIO_NOMBRE',
        url: '{{BENEFICIARIO_NOMBRE}}',
        value: 'BENEFICIARIO_NOMBRE',
      },
      {
        title: 'BENEFICIARIO_EMAIL',
        url: '{{BENEFICIARIO_EMAIL}}',
        value: 'BENEFICIARIO_EMAIL',
      },
      {
        title: 'BENEFICIARIO_DIRECCION',
        url: '{{BENEFICIARIO_DIRECCION}}',
        value: 'BENEFICIARIO_DIRECCION',
      },
      {
        title: 'BENEFICIARIO_PLAN',
        url: '{{BENEFICIARIO_PLAN}}',
        value: 'BENEFICIARIO_PLAN',
      },
      {
        title: 'BENEFICIARIO_TIPO_PLAN',
        url: '{{BENEFICIARIO_TIPO_PLAN}}',
        value: 'BENEFICIARIO_TIPO_PLAN',
      },
      {
        title: 'BENEFICIARIO_FECHA_INICIO_PLAN',
        url: '{{BENEFICIARIO_FECHA_INICIO_PLAN}}',
        value: 'BENEFICIARIO_FECHA_INICIO_PLAN',
      },
      {
        title: 'BENEFICIARIO_CANTIDAD',
        url: '{{BENEFICIARIO_CANTIDAD}}',
        value: 'BENEFICIARIO_CANTIDAD',
      },
      {
        title: 'BENEFICIARIO_SALARIO',
        url: '{{BENEFICIARIO_SALARIO}}',
        value: 'BENEFICIARIO_SALARIO',
      },
      {
        title: 'BENEFICIARIO_FECHA_CONSOLIDACION',
        url: '{{BENEFICIARIO_FECHA_CONSOLIDACION}}',
        value: 'BENEFICIARIO_FECHA_CONSOLIDACION',
      },
    ],
  };

  const tags = [societyTags, planTags, partnerTags, beneficiaryTags].filter(
    (tag) => tag
  );

  return tags;
}

export default oldTags;
