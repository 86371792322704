import React from 'react';

const IconInfo = () => (
  <div className="swal2-icon swal2-info swal2-icon-show d-flex">
    <div className="swal2-info-circular-line-left bg-white" />
    <span className="swal2-info-line-tip" />
    <span className="swal2-info-line-long" />
    <div className="swal2-info-ring" />
    <div className="swal2-info-fix bg-white" />
    <div className="swal2-info-circular-line-right bg-white" />
  </div>
);

export default IconInfo;
