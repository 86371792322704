import React, { FC } from 'react';

type InformationComponentProps = {
  mainMessage: string;
  infoMessage: string;
  colspan: number;
  imageIcon: string;
};

const InformationComponent: FC<InformationComponentProps> = ({
  mainMessage,
  infoMessage,
  colspan = 9,
  imageIcon,
}) => (
  <tr className="tb-tnx-item">
    <td colSpan={colspan}>
      <div className="nk-help justify-content-center my-4">
        <div className="nk-help-img">
          <img src={imageIcon} alt="imageIcon" />
        </div>
        <div className="nk-help-text flex-grow-0">
          {mainMessage && <h5>{mainMessage}</h5>}
          {infoMessage && <p className="text-soft">{infoMessage}</p>}
        </div>
      </div>
    </td>
  </tr>
);

export default InformationComponent;
