export default [
  'marti@derecho.com',
  'marti@sttok.com',
  'lilian@derecho.com',
  'lilian@sttok.com',
  'ferran@derecho.com',
  'ferran@sttok.com',
  'erasmo@sttok.com',
  'erasmo3@sttok.com',
  'javier@sttok.com',
];
