/* eslint-disable import/prefer-default-export */

export function transformHtmlTables(htmlString) {
  const resultHtml = htmlString.replace(
    /<table(.*?)>([\s\S]*?)<\/table>/g,
    (_match, _attributes, content) => {
      const tableAttributes =
        'style="width: 100%; border-collapse: collapse; border: 1px solid #ccc;"';
      const transformedContent = content.replace(
        /<colgroup>[\s\S]*?<\/colgroup>/,
        ''
      );
      return `<table ${tableAttributes}>${transformedContent}</table>`;
    }
  );

  return resultHtml;
}
