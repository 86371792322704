export default function addHtmlAlign(htmlText) {
  // Define a regex pattern to match paragraphs with "text-align: right;" or "text-align: center;"
  const pattern = /<p style="text-align: (right|center);">(.*?)<\/p>/g;

  // Replace matching paragraphs with the modified content
  const modifiedHTML = htmlText.replace(pattern, (_, alignment, content) => {
    // Wrap the content with <ins> if not already present
    const updatedContent = content.includes('<ins>')
      ? content
      : `<ins>${content}</ins>`;
    return `<p style="text-align: ${alignment};">${updatedContent}</p>`;
  });

  return modifiedHTML;
}
