const activePartner = (partner) => {
  const { actual, future, drafts } = partner?.sharesCount || {};
  const totalShares = actual + future + drafts;

  if (totalShares === 0 && partner.shares.length > 0) {
    return false;
  }
  return true;
};

export default activePartner;
