/* eslint-disable import/prefer-default-export */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function generatePdfBlob(htmlString) {
  return new Promise((resolve, reject) => {
    const html = htmlToPdfmake(htmlString);
    const document = { content: html };
    const pdfDocGenerator = pdfMake.createPdf(document);

    pdfDocGenerator.getBlob(
      (blob) => {
        resolve(blob);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
