/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */

import LogRocket from 'logrocket';

const ANONYMOUS_USER = 'anonymous_user';

export const initializeLogRocket = () => {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID);
};

export const identifyUser = (user) => {
  LogRocket.identify(user?._id || ANONYMOUS_USER, {
    name: user?.name || '',
    email: user?.email || '',
  });
};
