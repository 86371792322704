/* eslint-disable import/prefer-default-export */

export function clearTableLines(htmlString) {
  const modifiedHtml = htmlString.replace(
    /<td\b[^>]*>([\s\S]*?)<\/td>/g,
    (_match, tdContent) => {
      const modifiedTdAttributes =
        'style="border: 1px solid white; padding: 8px;"';
      return `<td ${modifiedTdAttributes}>${tdContent}</td>`;
    }
  );

  return modifiedHtml;
}
